export default function pricingEstimateParseResource({ data }) {
  const discounts = data?.pricing?.discounts;
  const amount = data?.pricing?.amount;
  const discountAmount = discounts?.periods;
  const discountRate = discountAmount
    ? Math.round((discountAmount * 100) / amount) / 100
    : undefined;

  return {
    reference: data?.reference,
    amount: discountRate ? amount - discountAmount : amount,
    oldAmount: amount,
    discount: discounts
      ? {
          amount: discountAmount,
          rate: discountRate,
        }
      : undefined,
    state: data?.state,
    meta: {
      isUsingCheckinAndCheckout: !!data?.pricing?.config,
    },
  };
}
