// dependencies
import React, { useMemo } from "react";
import { StyleSheet, css } from "aphrodite";

// components
import DatePickerMonth from "./DatePickerMonth";

// libraries
import EnhancedDate from "../../libraries/EnhancedDate";

const styles = StyleSheet.create({
  datePickerYear: {
    display: "flex",
    width: "100%",
  },
  month: {
    width: "100%",
  },
});

type PropsType = {
  from: EnhancedDate;
  to: EnhancedDate;
};

const DatePickerYear: React.FunctionComponent<PropsType> = (props) => {
  const { from, to } = props;

  return useMemo(() => {
    const tempDate = from.clone();

    const $months = [];

    while (tempDate.isLessOrEqualTo(to)) {
      $months.push(
        <div key={tempDate.format("m-y")} className={css(styles.month)}>
          <DatePickerMonth
            date={tempDate.clone()}
            month={tempDate.clone()}
            shouldAddOffset={!from.isEqualTo(tempDate)}
            shouldDisplayMaxWeekPerMonth={true}
          />
        </div>
      );

      tempDate.addMonth(1);
    }
    return <div className={css(styles.datePickerYear)}>{$months}</div>;
  }, [from.format("Y-m-d"), to.format("Y-m-d")]);
};

export default DatePickerYear;
