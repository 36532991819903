/**
 * Retourne la base pour chaque composante du temps.
 */

export const MONTH_TO_YEAR_BASE = 12; // 0-11

export const DAY_TO_WEEK_BASE = 7; // 0–6

export const HOURS_TO_DAY_BASE = 24; // 0–23

export const MINUTES_TO_HOURS_BASE = 60; // 0–59

export const SECONDS_TO_MINUTES_BASE = 60; // 0–59

export const MILLISECONDS_TO_SECOND_BASE = 1000; // 0–999

// At minimum, there is 4 weeks in one month.
// Assuming the week begins a sunday:
//   sunday, 1st to saturday 28th (for a leap year).
export const MIN_WEEKS_PER_MONTH = 4;

// At maximum, there is 6 weeks in one month.
// Assuming the week begins a sunday:
//   saturday, 1st to monday 31th.
export const MAX_WEEKS_PER_MONTH = 6;

export const DAY_NAMES = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
} as { [dayName: string]: number };
