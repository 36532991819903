// dependencies
import React, { useContext } from "react";

// libraries
import { noop } from "@cloudspire/legacy-shared/src/libraries";
import EnhancedDate from "@cloudspire/legacy-resources/src/libraries/EnhancedDate";

const datePickerContext = React.createContext({
  DatePickerDay: (() => null) as any,
  start: null as EnhancedDate,
  end: null as EnhancedDate,
  hover: null as EnhancedDate,
  min: null as EnhancedDate,
  readOnly: false,
  onChangeSelect: noop as (date: EnhancedDate) => void,
  onChangeHover: noop as (date: EnhancedDate) => void,
});

export const DatePickerProvider = datePickerContext.Provider;

export const useDatePicker = () => useContext(datePickerContext);
