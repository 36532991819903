// dependencies
import React, { useMemo } from "react";
import { StyleSheet, css } from "aphrodite";

// libraries
import EnhancedDate from "../../libraries/EnhancedDate";

// contexts
import { useDatePicker } from "../../containers/HeadlessDatePicker/datePicker";

type PropsType = {
  date: EnhancedDate;
  month: EnhancedDate;
};

const styles = StyleSheet.create({
  datePickerWeek: {
    display: "flex",
    width: "100%",
  },
});

const DatePickerWeek: React.FunctionComponent<PropsType> = (props) => {
  const { date, month } = props;

  const { DatePickerDay } = useDatePicker();

  return useMemo(() => {
    const tempDate = date.clone();

    const $days = [];

    while (
      tempDate.isLessOrEqualTo(
        date.clone().setDate((date.getDate() + 6) as any)
      )
    ) {
      $days.push(
        <DatePickerDay
          key={tempDate.format("y-m-d")}
          date={tempDate.clone()}
          type={
            tempDate.isLessThan(month)
              ? "leading"
              : tempDate.isGreaterThan(
                  month.clone().setDate(month.getNumberOfDaysInMonth())
                )
              ? "trailing"
              : "normal"
          }
        />
      );

      tempDate.setDate((tempDate.getDate() + 1) as any);
    }

    return <div className={css(styles.datePickerWeek)}>{$days}</div>;
  }, [date.format("y-m-W")]);
};

export default DatePickerWeek;
