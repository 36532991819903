// normalizers
import pricingEstimateParseResource from "./parseResource";

export function pricingEstimateParseSearch({ data }) {
  if (!data) {
    return null;
  }

  const newData = {};

  for (const [roomReference, pricing] of Object.entries(data?.data)) {
    newData[roomReference] = pricingEstimateParseResource({ data: pricing });
  }

  return {
    data: newData,
  };
}

/**
 * Normalise la recherche de hébergements avec le chargement infini.
 * @param {object} param0
 * @param {object} param0.bulkResponse
 */
export function pricingEstimateParseSearchInfinite({ bulkResponse }) {
  const body = {
    data: {},
  };

  bulkResponse = bulkResponse ?? [];

  for (const data of bulkResponse) {
    const pricings = data?.body?.data ?? {};

    for (const [roomReference, pricing] of Object.entries(pricings)) {
      body.data[roomReference] = pricingEstimateParseResource({
        data: pricing,
      });
    }
  }

  return body;
}
