/**
 * Natures
 */

export const BOOKING_NATURE_VALUE_PAR = "pa";
export const BOOKING_NATURE_VALUE_PRO = "pr";

export const BOOKING_NATURES = {
  [BOOKING_NATURE_VALUE_PAR]: {
    id: BOOKING_NATURE_VALUE_PAR,
    value: BOOKING_NATURE_VALUE_PAR,
    hidden: false,
  },
  [BOOKING_NATURE_VALUE_PRO]: {
    id: BOOKING_NATURE_VALUE_PRO,
    value: BOOKING_NATURE_VALUE_PRO,
    hidden: false,
  },
};

export const BOOKING_NATURE_LIST = Object.values(BOOKING_NATURES);

/**
 * Source
 */

export const BOOKING_SOURCE_VALUE_WEB = "wb";
export const BOOKING_SOURCE_VALUE_SR = "sr";
export const BOOKING_SOURCE_VALUE_FNGDF = "fn";

export const BOOKING_SOURCES = {
  [BOOKING_SOURCE_VALUE_WEB]: {
    id: BOOKING_SOURCE_VALUE_WEB,
  },
  [BOOKING_SOURCE_VALUE_SR]: {
    id: BOOKING_SOURCE_VALUE_SR,
  },
  [BOOKING_SOURCE_VALUE_FNGDF]: {
    id: BOOKING_SOURCE_VALUE_FNGDF,
  },
};

/**
 * Liste des paramètres nécessaire pour effectuer une estimation
 *   d'une réservation.
 */
export const ESTIMATE_INDUCE_REFRESH_PARAMETER_LIST_NAME_FOR_USER = [
  "checkinCheckout",
  "adults",
  "childs",
  "babies",
  "pets",
  "cats",
  "dogs",
  "insuranceId",
  "nature",
  "optionList",
  "discountCodeList",
];

/**
 * Nombre maximal d'animaux.
 */

export const MAX_PETS_LIMIT = 2;

/**
 * Workflow
 */

export const BOOKING_WORKFLOW_VALUE_NATIVE = "native";
export const BOOKING_WORKFLOW_VALUE_OWNER = "owner";

export const BOOKING_WORKFLOWS = {
  [BOOKING_WORKFLOW_VALUE_NATIVE]: BOOKING_WORKFLOW_VALUE_NATIVE,
  [BOOKING_WORKFLOW_VALUE_OWNER]: BOOKING_WORKFLOW_VALUE_OWNER,
};

/**
 * État de la réservation
 */

/** Devis (cet état ne verouille pas le planning de résa) */
export const BOOKING_STATE_VALUE_PREORDERED = "pre";
/** Option de réservation (cet état verouille le planning de résa) */
export const BOOKING_STATE_VALUE_PROMISED = "pro";
/** Réservation en cours de règlement */
export const BOOKING_STATE_VALUE_OPENED = "ope";
/** Réservation entièrement payée par le client */
export const BOOKING_STATE_VALUE_SOLDED = "sol";
/** Réservation refusée (propriétaire) */
export const BOOKING_STATE_VALUE_REFUSED = "ref";
/** Réservation abandonnée (paiement) */
export const BOOKING_STATE_VALUE_ABORTED = "abt";
/** Réservation annulée (agent de résa) */
export const BOOKING_STATE_VALUE_CANCELLED = "can";

export const BOOKING_STATES = {
  [BOOKING_STATE_VALUE_PREORDERED]: {
    id: BOOKING_STATE_VALUE_PREORDERED,
  },
  [BOOKING_STATE_VALUE_PROMISED]: {
    id: BOOKING_STATE_VALUE_PROMISED,
  },
  [BOOKING_STATE_VALUE_OPENED]: {
    id: BOOKING_STATE_VALUE_OPENED,
  },
  [BOOKING_STATE_VALUE_SOLDED]: {
    id: BOOKING_STATE_VALUE_SOLDED,
  },
  [BOOKING_STATE_VALUE_REFUSED]: {
    id: BOOKING_STATE_VALUE_REFUSED,
  },
  [BOOKING_STATE_VALUE_ABORTED]: {
    id: BOOKING_STATE_VALUE_ABORTED,
  },
  [BOOKING_STATE_VALUE_CANCELLED]: {
    id: BOOKING_STATE_VALUE_CANCELLED,
  },
};

export const BOOKING_STATE_LIST = Object.values(BOOKING_STATES);

/**
 * État de la validation par le propriétaire (oack).
 */

export const BOOKING_OACK_STATE_VALUE_OACK_UNKNOWN = "unk";
export const BOOKING_OACK_STATE_VALUE_OACK_CONFIRMED = "con";
export const BOOKING_OACK_STATE_VALUE_OACK_REFUSED = "ref";

export const BOOKING_OACK_STATES = {
  [BOOKING_OACK_STATE_VALUE_OACK_UNKNOWN]: {
    id: BOOKING_OACK_STATE_VALUE_OACK_UNKNOWN,
  },
  [BOOKING_OACK_STATE_VALUE_OACK_CONFIRMED]: {
    id: BOOKING_OACK_STATE_VALUE_OACK_CONFIRMED,
  },
  [BOOKING_OACK_STATE_VALUE_OACK_REFUSED]: {
    id: BOOKING_OACK_STATE_VALUE_OACK_REFUSED,
  },
};

export const BOOKING_OACK_STATE_LIST = Object.values(BOOKING_OACK_STATES);
